:root {
  /* Cores primárias */
  --color-new-primary: #12D0FF;
  --color-primary: #00a5e4;
  --color-primary-normal: #00a5e4;
  --color-hard-primary: #007eb0;
  --color-action-blue: #005c84;
  --color-light-primary: #66c3e8;
  --color-white-grey: #f7f7f7;
  --color-secondary: #4d4d4d;
  --color-danger: #cf6679;
  --color-primary-dark-white: var(--color-primary);
  --color-hover-select: #1967d2;

  /* Cores de fonte */
  --color-font-primary: #6f7985;
  --color-font-green: #056b09;
  --color-font-yellow: #baaa00;
  --color-font-orange: #d86f23;
  --color-font-red: #c32222;

  /* Cores brancas */
  --color-white: #ffffff;
  --color-always-white: #ffffff;
  --color-white-footer: #ffffff;
  --color-white-card: #ffffff;
  --color-white-input-background: #ffffff;
  --color-white-perguntas: #f9f9f9;

  /* Cores pretas */
  --color-black: #000;
  --color-always-black: #000000;
  --color-second-white-table: #f9f9f9;

  --color-medium-black: #231f20;
  --color-hard-gray: #4d4d4d;
  --color-grey-black: #221f20;

  /* Cores cinza */
  --color-medium-gray: #9e9e9e;
  --color-light-gray: #e8e8e8;
  --color-light-white: #fafafa;
  --color-normal-white: #FAFAFA;
  --color-txt-disable: #979797;
  --color-failed: #b50404;
  --color-gray-secondary: #393939;
  --color-card-grey: var(--color-light-gray);
  --color-gray-300: #d0d0d0;
  --color-gray-500: #d1d1d1;
  --color-border-custom-button: #dfe0eb;
  --color-grey-600: #9e9e9e;
  --color-second-background: #fafafa;
  --color-gray-light-backgroud-textarea: #f2f2f2;
  --color-background-banner-cookies: #f2f2f2;
  --color-disabled-table: #AAAAAA;
  --color-box-shadow: #0000001a;
  --color-border-button-action: #dfe0eb;
  --border-grey-light: #d6d6d6;

  /* Outras cores */
  --color-black-tranparent: rgba(0, 0, 0, 0.2);
  --color-gray-weak: #e3e3e3;
  --color-gray-always-weak: #e3e3e3;
  --color-gray-always-weak-disable: #e3e3e3;
  --color-white-code-bar: #f5f5f5;
  --color-white-white: #f6f6f6;
  --color-light-blue: #f4fcff;
  --color-menu-black: #221f20;
  --color-always-grey: #626262;
  --color-button-primary: #00a5e4;
  --color-card-white: #ffffff;
  --color-grey: #626262;
  --color-normal-grey: #626262;
  --color-input-border: var(--color-primary);
  --color-light-blue-card: #f4fcff;
  --color-card-date-white: #fff;
  --color-blue-200: #f4fcff;
  --color-blue-700: #1e3650;
  --color-grey-placeholder: #626262;
  --color-panel-inside-top: #dadada;

  /* Cores personalizadas */
  --blue-royal: #1e3650;
  --blue-royal-dark-white: var(--blue-royal);
  --blue-sabesp: #11d0ff;
  --btn-disabled: #dadada;
  --blue-300: #04a7e5;
  --blue-500: #45bae8;
  --blue-600: #005c84;
  --color-action-anchor: #005c84;
  --blue-always-royal: #1e3650;
  --blue-color-royal--button: var(--blue-always-royal);
  --color-primary-white-table: #ffffff;
  --color-border-table: var(--color-gray-weak);
  --color-formas-pagamento: rgba(12, 24, 44);
  --color-body-input: #fff;
  --color-label-grey: #e3e3e3;
  --color-border-label-details: #e3e3e3;
  --color-black-white-letter: #000;
  --grey-with-royal: #eeeeee;
  --color-grey-blue-table: #f9f9f9;
  --border-grey-white: #d1cfcf;
  --color-grey-blue-table2: #fff;
  --color-white-graph: #fff;
  --color-select-bill: var(--blue-always-royal);
  --active-item-list: #e3e3e3;
  --color-white-card-tbody: var(--color-always-white);
  --color-pink: #fd0707;
  --color-light-theme: #f4fcff;
  --color-primary-border-td: #00a5e4;
  --color-secondary-border-td: #005c84;
  --color-vertical-chart-line: #cacaca;
  --color-border-card-table: #254a73;
  --color-external-border-table: #c4c4c4;
  --color-border-reported-payment: #c8c8c8;
  --color-switch-background: #cacaca;
  --color-timeline-connector-efective: #00a5e4;
  --color-timeline-connector-analisys: #2fc535;
  --color-timeline-connector-error: #b50404;
  --color-expand-details: #fff;
  --color-details-bill: #f4fcff;
  --color-th-installment: #efefef;
  --color-td-installment: #fff;
  --color-border-installment-card: #c4c4c4;
  --color-button-installment-card: var(--color-always-black);
  --color-webkit-scrollbar: #f4f4f4;
  --color-webkit-scrollbar-background: #05a7e5;
  --color-card-not: rgba(0, 0, 0, 0.15);
  --color-separator-border-table: #2a5788;
  --color-button-cookies: #f2f2f2;
  --color-button-letter-cookies: #05a7e5;
  --color-always-blue-sabesp: #05a7e5;
  --color-blue-field-with-white: #fff;
  --color-white-with-gray-letter: #626262;
  --color-txt-disable: #979797;

  --color-disabled-mat-form-field: #f2f2f2;

  --color-border-transferencia-card: #e3e3e3;
  --color-link: #005c84;
  --color-background-input-label: #fff;
  --color-always-grey-border: #c8c8c8;
  --color-background-card-icon : #f2f2f2;
  --color-placeholder-select: #939292;
  --color-label-black: #000;
  --color-iot-chip-green: #edffe8;
  --color-sidebar: #fcfcfc;
  --color-active-link-sidebar: #f3f3f3;
  --color-border-sidebar-admin: #f1f1f1;

  /* Tema escuro */
  .theme-dark {
    --color-primary-normal: #fff;
    --color-normal-white: #2A3A55;
    --color-white-input-background: #2A3A55;
    --color-gray-weak: #232f42;
    --color-normal-grey: #fff;
    --color-gray-always-weak: #fff;
    --color-gray-always-weak-disable: #1E3650;
    --color-white-code-bar: #0c182c;
    --color-white: #0c182c;
    --color-menu-black: #0c182c;
    --color-second-white-table: #2a3a55;
    --color-primary-white-table: #232f42;
    --color-black: #fff;
    --color-failed: #ff8d97;
    --color-font-orange: #ffd109;
    --color-font-green: #2fc535;
    --color-light-white: #141414;
    --color-grey: #ffffff;
    --color-white-footer: #232f42;
    --color-white-grey: #232f42;
    --color-medium-black: #c9c9c9;
    --color-gray-secondary: #b7b7b7;
    --color-font-red: #c32222;
    --color-light-gray: var(--color-hard-gray);
    --color-action-blue: #00a5e4;
    --color-light-blue: #636363;
    --color-white-card: #424242;
    --color-button-primary: #fff;
    --color-card-white: #232f42;
    --color-hard-primary: #fff;
    --blue-royal: #0c182c;
    --blue-royal-dark-white: var(--color-always-white)
    --color-hard-gray: var(--color-always-white);
    --color-input-border: var(--color-always-black);
    --color-card-grey: #0c182c;
    --blue-color-royal--button: #ffffff;
    --color-light-blue-card: #2a3b55;
    --color-card-date-white: #0c182c;
    --color-border-table: #2a3a55;
    --color-formas-pagamento: rgba(12, 24, 44);
    --color-box-input: #232f42;
    --color-blue-700: #0c182c;
    --color-body-input: #232f42;
    --color-label-grey: #232f42;
    --color-border-label-details: #fff;
    --color-black-white-letter: #fff;
    --grey-with-royal: #0c182c;
    --color-grey-blue-table: #2a3a55;
    --border-grey-white: #ffffff31;
    --color-grey-blue-table2: #2a3a55;
    --color-white-perguntas: #1e3650;
    --color-border-custom-button: #0c182c;
    --color-white-graph: #2a3a55;
    --color-select-bill: #2a3a55;
    --active-item-list: #2a3b55;
    --color-white-card-tbody: #2a3a55;
    --color-pink: #ff8d97;
    --color-light-theme: #1e3650;
    --color-primary-border-td: #fff;
    --color-secondary-border-td: #00a5e4;
    --color-vertical-chart-line: #00a5e4;
    --color-border-card-table: #fff;
    --color-external-border-table: #fff;
    --color-border-reported-payment: #0c182c;
    --color-grey-placeholder: #fff;
    --color-blue-200: #1e3650;
    --color-timeline-connector-efective: #fff;
    --color-timeline-connector-analisys: #fff;
    --color-timeline-connector-error: #b50404;
    --color-grey-600: #fff;
    --blue-500: #c9c9c9;
    --color-second-background: #2a3a55;
    --color-expand-details: #1e3650;
    --color-panel-inside-top: #0c182c;
    --color-action-anchor: #fff;
    --color-details-bill: #0c182c;
    --color-th-installment: #0c182c;
    --color-td-installment: #2a3a55;
    --color-border-installment-card: #fff;
    --color-button-installment-card: #0c182c;
    --color-background-banner-cookies: #232f42;
    --color-button-cookies: #dadada;
    --color-button-letter-cookies: #626262;
    --color-always-blue-sabesp: #05a7e5;
    --color-blue-field-with-white: #2a3a55;
    --color-white-with-gray-letter: #fff;

    --color-disabled-mat-form-field: #0c182c;
    --color-gray-light-backgroud-textarea: #2a3a55;

    --color-border-transferencia-card: #fff;
    --color-link: #005c84;
    --color-primary-dark-white: var(--color-always-white)
    --color-background-input-label:  #2a3a55;
    --color-always-grey-border: #c8c8c8;
    --color-background-card-icon : #f2f2f2;
    --color-placeholder-select: #939292;
    --color-label-black: #c9c9c9;
    --color-hover-select: #1967d2;
    --color-border-button-action: #ffffff;
    --color-sidebar: #0c182c;
    --color-active-link-sidebar: #232f42;
    --color-border-sidebar-admin: #0c182c;

  }
}
